/* =============================================================================
Table of Contents preparation & interaction
============================================================================= */

jQuery(($) => {
  // console.log("Loaded from Custom toc");
  /* =================================================
    Inject TOC title/toggler into sticky TOC on mobile
    =================================================== */


  async function fetchAlgoliaSearchResults() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: ajax_url.apiUrl,
                method: 'POST',
                headers: {
                    'Auth': 'Bearer lZFxLb5oYb3gLOu1uTBFkugp2IlHurFUKrQCUlPkwaJVSSgegW4QTuEkgSJ4HX57',
                },
                data: { action: 'handle_algolia_search_request' },
                dataType: 'json',
                success: function (response) {
                    if (response && response.data) {
                        resolve(response.data); // Resolve with the data
                    } else {
                        reject('No data received'); // Reject if data is not present
                    }
                },
                error: function (error) {
                    console.error('Error from REST API:', error);
                    reject(error); // Reject with the error
                },
            });
        });
    }

    async function performTOC() {
        var searchResults =  await fetchAlgoliaSearchResults();

        const $tocContainer = $(".toc-container");
        if ($tocContainer.length) {
          const $title = $(
            '<a href="#" role="button" aria-expanded="false" class="toc-title-mobile"></a>'
          );
          $title.text(searchResults.tocTitle);
          $tocContainer.prepend($title);

          // Enable expansion/collapse of mobile TOC.
          $title.on("click", function() {
            $tocContainer.toggleClass("toc-container--expanded");
            $(this).attr(
              "aria-expanded",
              $tocContainer.hasClass("toc-container--expanded")
            );
            return false;
          });

          $("#toc-placeholder").on("click", function(e) {
            if ($(this).is(e.target)) {
              $title.click();
            }
          });

          // Close TOC on mobile when in-page anchor link clicked.
          $("ul.toc")
            .find('a[href*="#"]')
            .on("click", function() {
              // If going to a link on the same page and the TOC is expanded, collapse it.
              if (
                this.pathname === window.location.pathname &&
                $tocContainer.hasClass("toc-container--expanded")
              ) {
                $title.trigger("click");
              }
            });
        }

        /* =================================================
          Add additional active-trail classes to Paligo's dynamic TOC.
          =================================================== */
        // Process markup on click event (not needed until there's interaction anyway)
        // since another script is the one responsible for adding the 'active' class
        // later in the document ready pipeline.
        $(document).on("mousedown", "ul.toc:not(.rs-processed) .topic-link", () => {
          $("ul.toc .topic-link.active")
            .parents("li")
            .addClass("active-trail");

          // Mark TOC as processed so we don't run this handler again.
          $("ul.toc").addClass("rs-processed");
        });

        /* =================================================
          Highlight TOC item on scroll to section
          =================================================== */

        // Only do TOC highlighting if there's a TOC.
        if (!$tocContainer.length) {
          return;
        }

        /* IntersectionObserver setup
          =================================================== */

        // Stores the <section> elements being monitored for intersection.
        const observedElements = [];
        // A subset of the above array tracking which are currently intersecting.
        const intersecting = new Set();

        /**
         * Responds to intersection changes by highlighting active TOC item when
         * corresponding section scrolls into view.
         *
         * @param {Array}   entries Intersection entries being monitored.
         */
        const intersectionCallback = (entries) => {
          let active;
          const $toc = $("ul.toc");
          // Remove "active" class on all TOC links to start with.
          $toc.find("li li a").removeClass("active");

          for (const entry of entries) {
            // Keep track of all elements intersecting the viewport.
            if (entry.isIntersecting) {
              intersecting.add(entry.target);
            } else {
              intersecting.delete(entry.target);
            }
          }

          // Attempt to highlight each matching section.
          intersecting.forEach((el) => {
            const $candidate = $toc.find(`a[href$="#${el.id}"]`);
            const $prev = $candidate
              .parent()
              .prevAll()
              .children("a");
            const $next = $candidate
              .parent()
              .nextAll()
              .children("a");

            // Only highlight candidate if previous siblings haven't already been.
            if (!$prev.length || !$prev.is(".active")) {
              $candidate.addClass("active");

              // If one of next siblings has also been marked active, undo that.
              if ($next.length && $next.is(".active")) {
                $next.removeClass("active");
              }
            }
          });
        };

        const verticalOffset = parseInt($("html").css("margin-top")) + 150;
        const observer = new window.IntersectionObserver(intersectionCallback, {
          rootMargin: `-${verticalOffset}px 0px 0px`,
        });

        /* Populate observed elements
          =================================================== */
        $("#topic-content > section section[id]").each(function() {
          observedElements.push(this);
        });

        /* Set up the intersection observer
          =================================================== */
        // Clear out old elements and add new ones.
        observer.disconnect();
        observedElements.forEach((el) => observer.observe(el));
    }
    performTOC();
  
    $('a.topic-link[href*="#"]').each(function () {
      $(this).on('click', function (e) {
          var href = $(this).attr('href');
          var targetId = href.split('#')[1];
          if (!targetId) {
              return false;
          }
  
          var $section = $('#' + targetId);
          var headerHeight = $('.header').outerHeight() || 0;
          var sectionOffset = $section.offset().top;
          
            $("ul.toc li").removeClass("active-trail");
            $("ul.toc ul").each(function() {
              $(this).removeClass("active-last");
            });
  
            $(this).closest("ul").closest("li").addClass("active-trail active-trail-li");
            $(this).closest("ul").addClass("active-trail active-trail-li");
            $('ul.active-trail').closest("li").addClass("active-trail");
  
            setTimeout(() => {
              $(".active-trail-li").addClass("active-trail");
            }, 100);
  
            setTimeout(() => {
              $('ul.toc').each(function() {
                if ($(this).hasClass('active-trail')) {
                    $(this).find('li').each(function() {
                        var $activeLink = $(this).find('.topic-link.active');
                        if ($activeLink.length > 0) {
                            console.log( 'test user' );
                            $(this).addClass('active-trail');
                        }
                        $(this).closest("li").addClass("active-trail");
                    });
                }
              });
            }, 500);
            
          $('html, body').animate({
              scrollTop: sectionOffset - (headerHeight + 20),
          }, 100);
      });
    });

  // $(window).on('load', function() {
  //   setTimeout(() => {
  //     $('ul.toc').each(function() {
  //       if ($(this).hasClass('active-trail')) {
  //           $(this).find('li').each(function() {
  //               var $activeLink = $(this).find('.topic-link.active');
  //               if ($activeLink.length > 0) {
  //                   $(this).addClass('active-trail');
  //               }
  //           });
  //       }
  //     });
  //   }, 1000);
   
  //    to add border to only the granual level of ul
  //    $activeLink.parent('ul').addClass('active-last');
  // });
});
